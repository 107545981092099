@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  background: #f1f1f1;
  font-family: "Open Sans", sans-serif;
}

.reactEasyCrop_Container {
  z-index: 10 !important;
}

#ReactSimpleImageViewer {
  z-index: 10 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
